@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-inputs/styles/material.css';

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}
.bg-one {
  background-color: #48aec4;
}
.bg-two {
  background-color: #95c136;
}
.bg-three {
  background-color: #ef7e39;
}
.bg-four {
  background-color: #fbd74a;
}
.bg-gray {
  background-color: #d3d3d3;
}
.border-korianblue {
  border: solid 1px #013755 !important;
}
.bg-korianblue {
  background-color: #013755;
}
.korianblue {
  color: #013755;
}
.one {
  color: #48aec4 !important;
}
.clickable:hover {
  cursor: pointer;
}
.editable:hover {
  cursor: text;
}
.txt {
  font-size: 1.3rem !important;
}
.txt-sm {
  font-size: 1rem;
}

.br-blue {
  border-right: 1px solid #ccc !important;
}

div.pl-4 {
  padding-left: 40px !important;
}

.navbar {
  color: #fff;
  background-color: #013755;
}
.navbar-nav .nav-link,
.navbar-brand {
  color: #fff;
}
a {
  color: #fff !important;
}

.imgIcon {
  width: 25px;
}

.table td.no-border-top,
.table th.no-border-top {
  border-top: none !important;
}

a.dropdown-item {
  color: #212529 !important;
}

.loader {
  background: rgba($color: #fff, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    width: 100px;
  }
}

.nav-tabs a.nav-link {
  color: #212529 !important;
  &.active {
    color: #17a2b8 !important;
    font-weight: bold;
  }
}

.lower {
  text-transform: lowercase;
}

.btn-outline-info {
  color: #17a2b8 !important;
}

.btn-outline-info:hover {
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .hideonmobile {
    display: none !important;
  }
}
