.Table {
  .react-bs-table-pagination {
    .react-bootstrap-table-page-btns-ul {
      li {
        a {
          color: #035886 !important;
        }

        &.active {
          a {
            color: #fff !important;
          }
        }
      }
    }

    .react-bs-table-sizePerPage-dropdown {
      display: none;
    }
  }

  .react-bs-table table td {
    overflow: inherit !important;
    white-space: inherit !important;
    vertical-align: middle !important;
  }

  .closed-investigation {
    background-color: red
  }
}
